<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { useAppStore } from '@/store/modules/index.js';
export default {
  name: 'App',
  mounted() { 
    const appStore = useAppStore()
    this.$Message.config({ 
      duration: 3
    })
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.pagesbox .ivu-page-item-active{
   border-color: black;
   color: black;
} 
.pagesbox .ivu-page-item-active a{
  color: black;
} 
.pagesbox .ivu-page-item:hover {
  border-color: black ;
}
.pagesbox .ivu-page-item:hover a{
  color: black;
}
#app .lights li:hover{
  background: #fff !important;
  color: #888 !important;
  border-bottom: 2px solid #2d8cf0 !important;
}
#app .ivu-table-fixed-right {
  right: 5px !important;
}
.big-img-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
}
.big-img-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  z-index: 1000;
  /* overflow-y: scroll; */
}
</style>
