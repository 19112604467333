import Vue from "vue";
import App from "./App";
import router from "./router";
import ViewUI from "view-design";
import VueCropper from "vue-cropper";
import Viewer from "v-viewer";
import Treeselect from '@riophae/vue-treeselect' 
// import "view-design/dist/styles/iview.css";
import "viewerjs/dist/viewer.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import "./style/theme/index.less"
import "./style/main.css";

import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(PiniaVuePlugin);

Vue.use(VueCropper);
Vue.use(ViewUI, {
  modal: {
    maskClosable: false
  }
});

Vue.use(Viewer);
Vue.config.productionTip = false;
let pinia = createPinia();
Vue.use(pinia);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  render: h => h(App)
});

Viewer.setDefaults({
    zIndex: 99999,
    inline: false, //启用lnline模式
    button: true, //显示右上角关闭按钮
    navbar: true, //显示缩略图导航
    title: true, //显示当前图片的标题
    toolbar: true, //显示工具栏
    tooltip: true, //显示缩放百分比
    movable: true, //显示可移动
    zoomable: true, //图片是否可缩放
    rotatable: true, //图片是否可旋转
    scalable: true, //图片是否可翻转
    transition: true, //使用css3过度
    fullscreen: true, //播放时是否全屏
    keyboard: true, //是否支持键盘
    // url: "data-source" //设置大图片的url
});
Vue.component('Treeselect', Treeselect);

if (process.env.NODE_ENV === "production") {
  Vue.prototype.$uploadUrl =
    window.location.protocol + "//" + window.location.host + "/api";
} else if (process.env.NODE_ENV === "testing") {
  Vue.prototype.$uploadUrl = "https://test.cargosoon.online/api";
} else {
  Vue.prototype.$uploadUrl = "https://test.cargosoon.online/api";
}
