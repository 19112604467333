// import customemai from '@/view/marketingemail/customemail.vue'
// import mailbox from '@/view/marketingemail/mailbox.vue'
// import configure from '@/view/marketingemail/configure.vue'

export default [
  {
    path:'mailbox',
    name:'mailbox',
    meta:{
      name: "客户邮箱管理",
      power: "customer_email",
      title:'客户邮箱-Co-logistics'
    },
    component: () => import("@/view/marketingemail/mailbox.vue"),
  },
  {
    path:'customemai',
    name:'customemai',
    meta:{
      name: "客户邮件管理",
      power: "customer_send",
      title:'客户邮件-Co-logistics'
    },
    component: () => import("@/view/marketingemail/customemail.vue"),
  },
  {
    path:'configure',
    name:'configure',
    meta:{
      name: "销售邮箱配置",
      power: "email_config",
      title:'销售邮箱-Co-logistics'
    },
    component: () => import("@/view/marketingemail/configure.vue"),
  },
]