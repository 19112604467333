
export default [
  {
    path:'asklog',
    name:'asklog',
    meta:{
      title:'单询记录-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/asklog/asklog.vue"),
  },
  {
    path:'usaocean',
    name:'usaocean',
    meta:{
      title:'美国海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/usaocean.vue"),
  },
  {
    path:'usaoceanbus',
    name:'usaoceanbus',
    meta:{
      title:'美国海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/usaocbus.vue"),
  },
  {
    path:'eursale',
    name:'eursale',
    meta:{
      title:'海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/eursale.vue"),
  },
  {
    path:'eurbus',
    name:'eurbus',
    meta:{
      title:'海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/eurbus.vue"),
  },
  {
    path:'canadasale',
    name:'canadasale',
    meta:{
      title:'海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/canadasale.vue"),
  },
  {
    path:'canadabus',
    name:'canadabus',
    meta:{
      title:'海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/canadabus.vue"),
  },
  {
    path:'alonesale',
    name:'alonesale',
    meta:{
      title:'海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/alonesale.vue"),
  },
  {
    path:'alonebus',
    name:'alonebus',
    meta:{
      title:'海派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/alonebus.vue"),
  },
  {
    path:'usairsale',
    name:'usairsale',
    meta:{
      title:'空派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/usairsale.vue"),
  },
  {
    path:'usairbus',
    name:'usairbus',
    meta:{
      title:'空派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/usairbus.vue"),
  },
  {
    path:'airothersale',
    name:'airothersale',
    meta:{
      title:'空派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/airothersale.vue"),
  },
  {
    path:'airotherbus',
    name:'airotherbus',
    meta:{
      title:'空派单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/airotherbus.vue"),
  },
  {
    path:'seacabinetsale',
    name:'seacabinetsale',
    meta:{
      title:'海运单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/sea_cabinetsale.vue"),
  },
  {
    path:'seacabinetbus',
    name:'seacabinetbus',
    meta:{
      title:'海运单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/sea_cabinetbus.vue"),
  },
  {
    path:'seacurboxsale',
    name:'seacurboxsale',
    meta:{
      title:'海运单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/sea_cutboxsale.vue"),
  },
  {
    path:'seacurboxbus',
    name:'seacurboxbus',
    meta:{
      title:'海运单询-Co-logistics'
    },
    component: () => import("@/components/childern/asklist/sea_cutboxbus.vue"),
  },
  
]