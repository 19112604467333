import Vue from 'vue'
import Router from 'vue-router'
import ViewUI from 'view-design'

import module from './module.js' 
import { useAppStore } from '@/store/modules/index.js';

import {getStorage,clearStorsage, getLocal} from '../utils/common'

Vue.use(Router)

const router = new Router({
  base: "co",
  mode: "history",
  routes: [
    {
      path: "/upload",
      name: "upload",
      meta: {
        title: "登录-Co-logistics"
      },
      component: () => import("@/components/childern/upload.vue")
    },
    {
      path: "/login",
      name: "login",
      meta: {
        title: "登录-Co-logistics"
      },
      component: () => import("@/components/login.vue")
    },
    {
      path: "/newlogin",
      name: "newlogin",
      meta: {
        title: "登录-Co-logistics"
      },
      component: () => import("@/view/login/index.vue")
    },
    module,
    {
      path:'*',
      name: "index",
      redirect: '/login',
    }
  ]
});

router.beforeEach((to, from, next) => {
  const appStore = useAppStore();
  ViewUI.LoadingBar.start();
  appStore.setShowLoading(true)
  if (to.matched.length > 1) {
    if (to.matched[1].path != appStore.mainrouter) {
      appStore.setMainRouter(to.matched[1].path);
      appStore.SetRouter("/");
    }
  }

  if (to.matched.length > 2) {
    appStore.SetRouter(to.path);
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.name === "login" || to.name === "agent") {
    next();
  } else if (!getLocal("message")) {
    document.title = "登录-Co-logistics";
    next({
      name: "login",
      path: "/login",
      meta: {
        title: "登录-Co-logistics"
      }
    });
  } else next();
});

router.afterEach(route => {
  const appStore = useAppStore();
  appStore.setShowLoading(false)
  ViewUI.LoadingBar.finish();
});

export default router;
