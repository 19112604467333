
export default [
  {
    path:'agents',
    name:'agents',
    meta:{
      name: "代理商管理",
      power: "agent",
      title:'代理商管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/agents.vue"),
  },
  {
    path:'store',
    name:'store',
    meta:{
      name: "代理仓库管理",
      power: "house",
      title:'代理仓库管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/store.vue"),
  },
  {
    path:'ocean',
    name:'ocean',
    meta:{
      name: "美国海派价格管理",
      power: "seaus",
      title:'美国海派价格管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/ocean.vue"),
  },
  {
    path:'europe',
    name:'europe',
    meta:{
      name: "欧洲海派价格管理",
      power: "seaeurope",
      title:'欧洲海派价格管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/europe.vue"),
  },
  {
    path:'canda',
    name:'canda',
    meta:{
      name: "加拿大价格管理",
      power: "seaca",
      title:'加拿大价格管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/canda.vue"),
  },
  {
    path:'usaair',
    name:'usaair',
    meta:{
      name: "美国空派价格管理",
      power: "airus",
      title:'美国空派价格管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/usaair.vue"),
  },
  {
    path:'europerail',
    name:'europerail',
    meta:{
      name: "欧洲铁派价格管理",
      power: "ironeurope",
      title:'欧洲铁派价格管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/cerail.vue"),
  },
  {
    path:'expressprice',
    name:'expressprice',
    meta:{
      name: "快递价格管理",
      power: "addexpress",
      title:'快递价格管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/express/index.vue"),
  },
  {
    path:'cabinet',
    name:'cabinet',
    meta:{
      name: "海运整柜价格",
      power: "seabox",
      title:'海运整柜价格-Co-logistics'
    },
    component: () => import("@/components/childern/transform/cabinet.vue"),
  },
  {
    path:'pricetable',
    name:'pricetable',
    meta:{
      name: "海派价格表",
      power: "price_excel",
      title:'海派价格表-Co-logistics'
    },
    component: () => import("@/components/childern/transform/priceTable/index.vue"),
  },
  {
    path:'proname',
    name:'proname',
    meta:{
      name: "品名池管理",
      power: "pool",
      title:'品类管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/proname.vue"),
  },
  {
    path:'country',
    name:'country',
    meta:{
      name: "运输国家管理",
      power: "country",
      title:'运输国家管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/country.vue"),
  },
  
  {
    path:'fbacode',
    name:'fbacode',
    meta:{
      name: "亚马逊仓库地址管理",
      power: "fbahouse",
      title:'亚马逊地址管理-Co-logistics'
    },
    component: () => import("@/components/childern/transform/fbacode.vue"),
  },
  {
    path:'product',
    name:'product',
    meta:{
      name: "客户端管理",
      power: "customer",
      title:'品名管理-Co-logistics',
      noShow: true
    },
    component: () => import("@/components/childern/transform/productname.vue"),
  },
]