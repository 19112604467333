
export default [
  // {
  //   path:'fullorder',
  //   name:'fullorder',
  //   meta:{
  //     name: "海运订舱记录",
  //     power: "seaboxlog",
  //     title:'海运订舱记录-Co-logistics'
  //   },
  //   component: () => import("@/components/childern/inquirylist/fulllog.vue"),
  // },
  {
    path:'business',
    name:'business',
    meta:{
      title:'我的海派-Co-logistics',
      name: "我的海派(商务)",
      power: "manage_inquiry",
    },
    component: () => import("@/components/childern/inquirylist/sea/business.vue"),
  },
  {
    path:'operation',
    name:'operation',
    meta:{
      title:'我的海派-Co-logistics',
      name: "我的海派(操作)",
      power: "opera_inquiry",
    },
    component: () => import("@/components/childern/inquirylist/sea/operation.vue"),
  },
  
  {
    path:'airbus',
    name:'airbus',
    meta:{
      name: "我的空派(商务)",
      power: "manage_inquiry_air",
      title:'我的空派-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/air/business.vue"),
  },
  {
    path:'airop',
    name:'airop',
    meta:{
      name: "我的空派(操作)",
      power: "opera_inquiry_air",
      title:'我的空派-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/air/operation.vue"),
  },
  {
    path:'ironbus',
    name:'ironbus',
    meta:{
      name: "我的铁派(商务)",
      power: "manage_inquiry_iron",
      title:'我的铁派-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/iron/business.vue"),
  },
  {
    path:'ironop',
    name:'ironop',
    meta:{
      name: "我的铁派(操作)",
      power: "opera_inquiry_iron",
      title:'我的铁派-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/iron/operation.vue"),
  },
  {
    path:'fclbus',
    name:'fclbus',
    meta:{
      name: "我的整柜(商务)",
      power: "manage_inquiry_sea",
      title:'我的整柜-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/fcl/business.vue"),
  },
  {
    path:'fclop',
    name:'fclop',
    meta:{
      name: "我的整柜(操作)",
      power: "opera_inquiry_sea",
      title:'我的整柜-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/fcl/operation.vue"),
  },
  {
    path:'record',
    name:'record',
    meta:{
      name: "查价记录",
      power: "code_inquiry",
      title:'查价记录-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/record.vue"),
  },
  {
    path:'draft',
    name:'draft',
    meta:{
      name: "草稿箱",
      power: "draftbox",
      title:'草稿箱-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/draft.vue"),
  },
  {
    path:'cargosale',
    name:'cargosale',
    meta:{
      name: "销售货量报表",
      power: "report_sale",
      title:'货量报表-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/cargo_report/sale.vue"),
  },
  {
    path:'saleprofit',
    name:'saleprofit',
    meta:{
      name: "销售利润统计",
      power: "saleprofit",
      title:'销售利润统计-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/sale_profit/index.vue"),
  },
  {
    path:'cargobusiness',
    name:'cargobusiness',
    meta:{
      name: "商务货量报表",
      power: "report_manage",
      title:'货量报表-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/cargo_report/business.vue"),
  },
  {
    path:'cargooperation',
    name:'cargooperation',
    meta:{
      name: "操作货量报表",
      power: "report_operate",
      title:'货量报表-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/cargo_report/operation.vue"),
  },
  {
    path:'businesskpi',
    name:'businesskpi',
    meta:{
      name: "商务KPI报表",
      power: "kpi_manage",
      title:'商务KPI报表-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/kpi/business-kpi.vue"),
  },
  {
    path:'operationkpi',
    name:'operationkpi',
    meta:{
      name: "操作KPI报表",
      power: "kpi_operate",
      title:'操作KPI报表-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/kpi/opera-kpi.vue"),
  },
  {
    path:'question',
    name:'question',
    meta:{
      name: "问题单管理",
      power: "abnormal_order",
      title:'问题单-Co-logistics'
    },
    component: () => import("@/components/childern/inquirylist/question/index.vue"),
  },
]