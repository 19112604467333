import qs from 'qs'
import axios from "./requert"

// 获取州列表
export const getSta = (params)=>axios.get('/transport/seeprice/get_us_continent', {params})

// 获取城市列表
export const getCity = (params)=>axios.get('/transport/seeprice/get_us_city', {params})

// 获取transport/agent/get_pool_info
export const getMesPool = (params)=>axios.get('/transport/seeprice/get_product_pool', {params}) 

// 上传图片
export const upLoadImg = (data)=>axios.post('/transport/seeprice/upload', data)

// 获取港口
export const getconlist = ()=>axios.get('/transport/seeprice/selprice')

// fbacode
export const getFbalist = (params)=>axios.get('/transport/fbahouse/search', {params})

// 全部国家
export const getCountrylist = (params)=>axios.get('/transport/country/get_sel_country', {params})

// 编辑自定义列表
export const saveCustomList = (data)=>axios.post('/road/opera_inquiry/save_custom_list', qs.stringify(data))

// 获取自定义列表
export const getCustomList = (data)=>axios.post('/road/opera_inquiry/get_custom_list', qs.stringify(data))

