import { defineStore } from "pinia";
import { ref } from "vue"

export const useAppStore = defineStore("app",()=>{
  const actrouter = ref('/')
  const mainrouter = ref('/')
  const unread_num = ref(0) // 未读消息
  const new_register = ref(0) // 未读新用户
  const news_sys = ref(0) // 系统通知
  const all_num = ref(0); // 所有通知
  const showLoading = ref(false)

  function SetRouter ( router ) {
    actrouter.value = router
    if(router === '/userlist/usermanager'){
      setNewRegister(0);
    }
  }

  function setMainRouter ( router ) {
    mainrouter.value = router
  }

  function setUnread ( num ) {
    unread_num.value = num
    all_num.value = num + new_register.value + news_sys.value;
  }

  function setNewRegister ( num ) {
    new_register.value = num
    all_num.value = num + unread_num.value + news_sys.value;
  }

  function setNewsSys ( num ) {
    news_sys.value = num
    all_num.value = num + new_register.value + unread_num.value;
  }

  function setShowLoading ( bool ) {
    showLoading.value = bool
  }

  return {
    mainrouter,
    actrouter,
    unread_num,
    new_register,
    all_num,
    news_sys,
    showLoading,
    SetRouter,
    setMainRouter,
    setUnread,
    setNewRegister,
    setNewsSys,
    setShowLoading
  }
}) 