export default
  {
    path: 'track',
    name: 'track',
    meta: {
      name: "货物跟踪管理",
      power: "cargo",
      title: '货物跟踪-Co-logistics'
    },
    component: () => import("@/layout/components/commonmenu/index.vue"),
    children: [
      // {
      //   path: 'ship',
      //   name: 'ship',
      //   meta: {
      //     title: '货物跟踪-Co-logistics'
      //   },
      //   component: () => import("@/components/childern/track/ship.vue"),
      // },
      // {
      //   path: 'airplan',
      //   name: 'airplan',
      //   meta: {
      //     title: '货物跟踪-Co-logistics'
      //   },
      //   component: () => import("@/components/childern/track/airplan.vue"),
      // },
      {
        path: 'saleship',
        name: 'saleship',
        meta: {
          name: "我的海派(销售)",
          power: "sea_transport_sale",
          title: '货物跟踪-Co-logistics'
        },
        component: () => import("@/components/childern/track/saleship.vue"),
      },
      {
        path: 'saleair',
        name: 'saleair',
        meta: {
          name: "我的空派(销售)",
          power: "air_transport_sale",
          title: '货物跟踪-Co-logistics'
        },
        component: () => import("@/components/childern/track/saleair.vue"),
      },
      {
        path: 'salerailway',
        name: 'salerailway',
        meta: {
          name: "我的铁派(销售)",
          power: "iron_transport_sale",
          title: '货物跟踪-Co-logistics'
        },
        component: () => import("@/components/childern/track/sale_railway.vue"),
      },
      {
        path: 'salefcl',
        name: 'salefcl',
        meta: {
          name: "我的整柜(销售)",
          power: "box_transport_sale",
          title: '货物跟踪-Co-logistics'
        },
        component: () => import("@/components/childern/track/salefcl.vue"),
      },
      
      // {
      //   path: 'trailway',
      //   name: 'trailway',
      //   meta: {
      //     title: '货物跟踪-Co-logistics'
      //   },
      //   component: () => import("@/components/childern/track/railway.vue"),
      // },
      
      {
        path: 'reason',
        name: 'reason',
        meta: {
          name: "延误数据管理",
          power: "reason",
          title: '延误数据-Co-logistics'
        },
        component: () => import("@/components/childern/track/reason/index.vue"),
      },
      {
        path: 'airdata',
        name: 'airdata',
        meta: {
          name: "机场数据管理",
          power: "air",
          title: '机场数据-Co-logistics'
        },
        component: () => import("@/components/childern/track/airdata/index.vue"),
      },
      {
        path: 'railwaydata',
        name: 'railwaydata',
        meta: {
          name: "铁派站点管理",
          power: "iron_site",
          title: '机场数据-Co-logistics'
        },
        component: () => import("@/components/childern/track/railwaydata/index.vue"),
      },
    ]
  };