export default {
  path: "finance",
  name: "finance",
  component: () => import("@/layout/components/finance.vue"),
  meta: {
    title: "财务管理-Co-logistics",
    power: "finance",
    name: "财务管理"
  },
  children: [
    {
      path: "rate",
      name: "rate",
      meta: {
        title: "汇率管理-Co-logistics",
        power: "exchange",
        name: "汇率管理",
      },
      component: () => import("@/view/finance/rate/index.vue")
    },
    {
      path: "info",
      name: "info",
      meta: {
        title: "账户管理-Co-logistics",
        power: "info",
        name: "账户管理",
      },
      component: () => import("@/view/finance/info/index.vue")
    },
    {
      path: "order",
      name: "order",
      meta: {
        title: "订单列表-Co-logistics",
        power: "order",
        name: "订单列表",
      },
      component: () => import("@/view/finance/order/index.vue")
    },
    {
      path: "charge",
      name: "charge",
      meta: {
        title: "费用列表-Co-logistics",
        power: "charge",
        name: "费用列表",
      },
      component: () => import("@/view/finance/charge/index.vue")
    },
    {
      path: "cancel",
      name: "cancel",
      meta: {
        title: "核销记录-Co-logistics",
        power: "cancel",
        name: "核销记录",
      },
      component: () => import("@/view/finance/cancel/index.vue")
    },
    {
      path: "invoice",
      name: "invoice",
      meta: {
        title: "发票管理-Co-logistics",
        power: "invoice",
        name: "发票管理",
      },
      component: () => import("@/view/finance/invoice/index.vue")
    },
    {
      path: "reconcile",
      name: "reconcile",
      meta: {
        title: "对账管理-Co-logistics",
        power: "reconcile",
        name: "对账管理",
      },
      component: () => import("@/view/finance/reconcile/index.vue")
    },
    {
      path: "salerPercentage",
      name: "salerPercentage",
      meta: {
        title: "销售提成-Co-logistics",
        power: "salerPercentage",
        name: "销售提成",
      },
      component: () => import("@/view/finance/percentage/saler.vue")
    },
    {
      path: "businessPercentage",
      name: "businessPercentage",
      meta: {
        title: "商务绩效-Co-logistics",
        power: "businessPercentage",
        name: "商务绩效",
      },
      component: () => import("@/view/finance/percentage/business.vue")
    },
    {
      path: "operatorPercentage",
      name: "operatorPercentage",
      meta: {
        title: "操作绩效-Co-logistics",
        power: "operatorPercentage",
        name: "操作绩效",
      },
      component: () => import("@/view/finance/percentage/operator.vue")
    },
    {
      path: "otherPercentage",
      name: "otherPercentage",
      meta: {
        title: "其他绩效-Co-logistics",
        power: "otherPercentage",
        name: "其他绩效",
      },
      component: () => import("@/view/finance/percentage/other.vue")
    },
    
    {
      path: "report",
      name: "report",
      meta: {
        title: "报表管理-Co-logistics",
        power: "report",
        name: "报表管理",
      },
      component: () => import("@/view/finance/report/index.vue")
    },
    {
      path: "confirm",
      name: "confirm",
      meta: {
        title: "销售确认提成-Co-logistics",
        power: "confirm",
        name: "销售确认提成",
      },
      component: () => import("@/view/finance/confirm/index.vue")
    },
    {
      path: "ranklist",
      name: "ranklist",
      meta: {
        title: "排行榜-Co-logistics",
        power: "Ranking",
        name: "排行榜",
      },
      component: () => import("@/view/finance/rank/index.vue")
    },
    {
      path: "statistics",
      name: "statistics",
      meta: {
        title: "统计-Co-logistics",
        power: "stat",
        name: "统计",
      },
      component: () => import("@/view/finance/statistics/index.vue")
    },
    {
      path: "spend",
      name: "spend",
      meta: {
        title: "支出管理-Co-logistics",
        power: "excost",
        name: "支出管理",
      },
      component: () => import("@/view/finance/spend/index.vue")
    },
  ]
};
