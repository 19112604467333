import axios from "axios";
// import { config } from "vue/types/umd";
import router from '@/router'
import { Message } from 'view-design'
import { getLocal, clearLocal, clearStorsage } from "./common";

const instance = axios.create({
  timeout: 60000,
})

instance.interceptors.request.use(config => {
  const mes = getLocal('message')
  if (mes) {
    const token = mes.token
    config.headers.token = token
  }
  return config
}, e => Promise.reject(e)
)

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code != 0) {
        if (response.data.code === 206) {
          clearStorsage();
          clearLocal();
          router.replace({
            path: '/login'
          })
          Message.error('登录过期，请重新登录');
        }
        else {
          Message.error(response.data.msg);
          return Promise.reject(response)
        }
      } else {
        return Promise.resolve(response)
      }
    } else {
      Message.error('系统错误');
      return Promise.reject(response)
    }
  },
  (error) => {
    Message.error('系统错误');
    if (error.response.status) {
      switch (error.response.status) {
        case -1:
          router.replace({
            path: '/login'
          })
          break;
      }
    }
  }
)
export { instance as axios };
