
export default [
  {
    path: 'talktags',
    name: 'talktags',
    meta: {
      title: '常用语管理-Co-logistics',
      power: "finance",
    },
    component: () => import("@/view/userlist/talktags/index.vue"),
  },
  {
    path: 'member',
    name: 'member',
    meta: {
      power: "finance",
      title: '信息审核-Co-logistics'
    },
    component: () => import("@/view/userlist/member/index.vue"),
  },
  {
    path: 'searchlog',
    name: 'searchlog',
    meta: {
      power: "finance",
      title: '查价记录-Co-logistics'
    },
    component: () => import("@/view/userlist/search_log.vue"),
  },
  {
    path: 'usermanager',
    name: 'usermanager',
    meta: {
      power: "finance",
      title: '客户账户管理-Co-logistics'
    },
    component: () => import("@/view/userlist/myuser.vue"),
  },
  {
    path: 'blogmanager',
    name: 'blogmanager',
    meta: {
      power: "finance",
      title: '博客管理-Co-logistics'
    },
    component: () => import("@/view/userlist/myblog.vue"),
  },
  {
    path: 'emaiconfig',
    name: 'emaiconfig',
    meta: {
      power: "finance",
      title: '邮件配置管理-Co-logistics'
    },
    component: () => import("@/view/userlist/email/emailConfig.vue"),
  },
  {
    path: 'emailtmp',
    name: 'emailtmp',
    meta: {
      power: "finance",
      title: '邮件模板管理-Co-logistics'
    },
    component: () => import("@/view/userlist/email/emailTmp.vue"),
  },
  {
    path: 'emailstatus',
    name: 'emailstatus',
    meta: {
      power: "finance",
      title: '邮件日志管理-Co-logistics'
    },
    component: () => import("@/view/userlist/email/emailstatus.vue"),
  },
  {
    path: 'address',
    name: 'address',
    meta: {
      power: "finance",
      title: '客户地址管理-Co-logistics'
    },
    component: () => import("@/view/userlist/address.vue"),
  },
  {
    path: 'supplier',
    name: 'supplier',
    meta: {
      power: "finance",
      title: '供货商管理管理-Co-logistics'
    },
    component: () => import("@/view/userlist/supplier/index.vue"),
  },
  {
    path: 'ipaddress',
    name: 'ipaddress',
    meta: {
      power: "finance",
      title: 'ip地址管理-Co-logistics'
    },
    component: () => import("@/view/userlist/ipaddress.vue"),
  },
  {
    path: 'card',
    name: 'card',
    meta: {
      power: "finance",
      title: '客户卡片管理-Co-logistics'
    },
    component: () => import("@/view/userlist/card.vue"),
  },
  {
    path: 'water',
    name: 'water',
    meta: {
      power: "finance",
      title: '客户流水管理-Co-logistics'
    },
    component: () => import("@/view/userlist/water.vue"),
  },
  {
    path: 'reflect',
    name: 'reflect',
    meta: {
      power: "finance",
      title: '钱包提现管理-Co-logistics'
    },
    component: () => import("@/view/userlist/reflect.vue"),
  },
  {
    path: 'recharge',
    name: 'recharge',
    meta: {
      power: "finance",
      title: '钱包充值管理-Co-logistics'
    },
    component: () => import("@/view/userlist/recharge.vue"),
  },
  {
    path: 'balance',
    name: 'balance',
    meta: {
      power: "finance",
      title: '钱包余额管理-Co-logistics'
    },
    component: () => import("@/view/userlist/balance.vue"),
  },
  {
    path: 'mycustom',
    name: 'mycustom',
    meta: {
      power: "finance",
      title: '我的客户信息-Co-logistics'
    },
    component: () => import("@/view/userlist/mycustom.vue"),
  },
  {
    path: 'customser',
    name: 'customser',
    meta: {
      power: "finance",
      title: '客服席位管理-Co-logistics'
    },
    component: () => import("@/view/userlist/customser.vue"),
  },
  {
    path: 'allcustom',
    name: 'allcustom',
    meta: {
      power: "finance",
      title: '客户消息总台-Co-logistics'
    },
    component: () => import("@/view/userlist/allcustom.vue"),
  },
  {
    path: 'custags',
    name: 'custags',
    meta: {
      power: "finance",
      title: '客户标签-Co-logistics'
    },
    component: () => import("@/view/userlist/custags.vue"),
  },
  {
    path: 'notify',
    name: 'notify',
    meta: {
      power: "finance",
      title: '客户通知-Co-logistics'
    },
    component: () => import("@/view/userlist/notify.vue"),
  },
  {
    path: 'viplabel',
    name: 'viplabel',
    meta: {
      power: "finance",
      title: '会员等级-Co-logistics'
    },
    component: () => import("@/view/userlist/viplabel.vue"),
  },
  {
    path: 'integral',
    name: 'integral',
    meta: {
      power: "finance",
      title: '会员积分-Co-logistics'
    },
    component: () => import("@/view/userlist/integral.vue"),
  },
  {
    path: 'customreport',
    name: 'customreport',
    meta: {
      power: "finance",
      title: '客户标价管理-Co-logistics'
    },
    component: () => import("@/view/userlist/customreport.vue"),
  },
  {
    path: 'shopsprice',
    name: 'shopsprice',
    meta: {
      power: "finance",
      title: '货运价格管理-Co-logistics'
    },
    component: () => import("@/view/userlist/shops/index.vue"),
  },
  {
    path: 'storelist',
    name: 'storelist',
    meta: {
      power: "finance",
      title: '货运中转仓管理-Co-logistics'
    },
    component: () => import("@/view/userlist/store.vue"),
  },
  {
    path: 'customorder',
    name: 'customorder',
    meta: {
      power: "finance",
      title: '货运订舱管理-Co-logistics'
    },
    component: () => import("@/view/userlist/order/index.vue"),
  },
  {
    path: 'helpcenter',
    name: 'helpcenter',
    meta: {
      power: "finance",
      title: '帮助中心-Co-logistics'
    },
    component: () => import("@/view/userlist/help/index.vue"),
  },
  {
    path: 'coupon',
    name: 'coupon',
    meta: {
      power: "finance",
      title: '折扣券管理-Co-logistics'
    },
    component: () => import("@/view/userlist/coupon/index.vue"),
  },
  {
    path: 'fastmessage',
    name: 'fastmessage',
    meta: {
      power: "finance",
      title: '快捷信息管理-Co-logistics'
    },
    component: () => import("@/view/userlist/fastmessage/index.vue"),
  },
  {
    path: 'allocation',
    name: 'allocation',
    meta: {
      power: "finance",
      title: '客服分配管理-Co-logistics'
    },
    component: () => import("@/view/userlist/allocation/index.vue"),
  },
  {
    path: 'opfs',
    name: 'opfs',
    meta: {
      power: "finance",
      title: '客户店铺-Co-logistics'
    },
    component: () => import("@/view/userlist/opfs/index.vue"),
  },
]