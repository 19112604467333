import qs from "qs";
import { axios } from "@/utils/axios";

if (process.env.NODE_ENV === "production") {
  // 生产包
  axios.defaults.baseURL =
    window.location.protocol + "//" + window.location.host + "/api";
  // axios.defaults.baseURL = 'https://test.co-logistics.cn/api'
} else if (process.env.NODE_ENV === "testing") {
  // 测试包
  axios.defaults.baseURL = "https://test.cargosoon.online/api";
} else {
  // 本地
  // axios.defaults.baseURL = "http://192.168.0.84:9001";
  axios.defaults.baseURL = 'https://test.cargosoon.online/api'
}

export const get = (url, params = {}) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export const post = (url, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(data))
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export const put = (url, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(url, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export const del = url =>
  new Promise((resolve, reject) => {
    axios
      .delete(url, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export default axios;
