export default [
  {
    path: 'attribute',
    name: 'attribute',
    meta: {
      name: "产品属性管理",
      power: "product_specs",
      title: '产品属性管理-Co-logistics'
    },
    component: () => import("@/components/childern/purchlist/attribute.vue"),
  },
  {
    path: 'category',
    name: 'category',
    meta: {
      name: "产品类别管理",
      power: "product_type",
      title: '产品类别管理-Co-logistics'
    },
    component: () => import("@/components/childern/purchlist/category.vue"),
  },
  {
    path: 'mylink',
    name: 'mylink',
    meta: {
      name: "采购产品库管理",
      power: "product",
      title: '采购产品库-Co-logistics'
    },
    component: () => import("@/components/childern/purchlist/mylink.vue"),
  },
  {
    path: 'alllink',
    name: 'alllink',
    meta: {
      name: "产品采购报价单管理",
      power: "product_need",
      title: '产品采购报价单管理-Co-logistics'
    },
    component: () => import("@/components/childern/purchlist/alllist.vue"),
  },
  {
    path: 'salelist',
    name: 'salelist',
    meta: {
      name: "销售产品库管理",
      power: "sale_product",
      title: '销售产品库管理-Co-logistics'
    },
    component: () => import("@/components/childern/purchlist/salelist.vue"),
  },
  {
    path: 'purchorder',
    name: 'purchorder',
    meta: {
      name: "采购下单管理",
      power: "product_order_log",
      title: '采购下单管理-Co-logistics'
    },
    component: () => import("@/components/childern/purchlist/order/index.vue"),
  },
  {
    path: 'examine',
    name: 'examine',
    meta: {
      name: "下单审核管理",
      power: "product_really",
      title: '下单审核管理-Co-logistics'
    },
    component: () => import("@/components/childern/purchlist/examine/index.vue"),
  },
  {
    path: 'productList',
    name: 'productList',
    redirect: 'productList/list',
    meta: {
      name: "产品库管理",
      power: "product_sku",
      title: '产品库管理-Co-logistics',
      showChildren: false
    },
    component: () => import("@/layout/components/commonmenu/Menu.vue"),
    children: [
      {
        path: "list",
        name: "list",
        meta: {
          title: "产品库管理-Co-logistics",
          power: "product_sku",
          name: "产品库管理",
          noShow: false
        },
        component: () => import("@/components/childern/purchlist/productList/index.vue")
      },
      {
        path: "add",
        name: "add",
        meta: {
          title: "创建产品-Co-logistics",
          power: "Order",
          name: "创建产品",
          noShow: false
        },
        component: () => import("@/components/childern/purchlist/productList/add.vue")
      },
    ]
  },
]