export default {
  path: "price",
  name: "price",
  component: () => import("@/layout/components/commonmenu/index.vue"),
  meta: {
    title: "查价管理-Co-logistics",
    power: "price",
    name: "查价管理"
  },
  children:[
    {
      path: "ocean",
      name: "oceanprice",
      meta: {
        title: "查价管理-Co-logistics",
        power: "seauspricce",
        name: "海派查价"
      },
      component: () => import("@/layout/components/commonmenu/Menu.vue"),
      children:[
        {
          path:'usacheck',
          name:'usacheck',
          meta:{
            title:'美国海派查价-Co-logistics',
            power: "seauspricce",
            name: "美国海派查价"
          },
          component: () => import("@/components/childern/check/usacheck.vue"),
        },
        {
          path:'europecheck',
          name:'europecheck',
          meta:{
            title:'欧洲海派查价-Co-logistics',
            power: "seepriceeurope",
            name: "欧洲海派查价"
          },
          component: () => import("@/components/childern/check/europecheck.vue"),
        },
        {
          path:'canada',
          name:'canada',
          meta:{
            title:'加拿大海派查价-Co-logistics',
            power: "seepriceca",
            name: "加拿大海派查价"
          },
          component: () => import("@/components/childern/check/canada.vue"),
        },
        {
          path:'alone',
          name:'alone',
          meta:{
            title:'海派单询查价-Co-logistics',
            power: "single_price",
            name: "海派单询查价"
          },
          component: () => import("@/components/childern/check/alone.vue"),
        }
      ]
    },
    {
      path:'usaaircheck',
      name:'usaaircheck',
      meta:{
        title:'美国空派-Co-logistics',
        power: "seepriceusair",
        name: "美国空派"
      },
      component: () => import("@/view/airlist/usacheck.vue"),
    },
    // {
    //   path:'airlist',
    //   name:'airlist',
    //   meta:{
    //     title:'空派查价-Co-logistics',
    //     power: "seepriceusair",
    //     name: "空派查价"
    //   },
    //   component: () => import("@/layout/components/commonmenu/Menu.vue"),
    //   children:[
    //     {
    //       path:'usaaircheck',
    //       name:'usaaircheck',
    //       meta:{
    //         title:'美国空派-Co-logistics',
    //         power: "seepriceusair",
    //         name: "美国空派"
    //       },
    //       component: () => import("@/view/airlist/usacheck.vue"),
    //     },
    //     {
    //       path:'other',
    //       name:'other',
    //       meta:{
    //         title:'其它单询-Co-logistics',
    //         power: "single_price_air",
    //         name: "其它单询"
    //       },
    //       component: () => import("@/view/airlist/other.vue"),
    //     }
    //   ]
    // },
    {
      path:'railway',
      name:'railway',
      meta:{
        title:'铁派查价-Co-logistics',
        power: "ironeuropeprice",
        name: "铁派查价"
      },
      component: () => import("@/layout/components/commonmenu/Menu.vue"),
      children:[
        {
          path:'europerailway',
          name:'europerailway',
          meta:{
            title:'欧洲铁派查价-Co-logistics',
            power: "ironeuropeprice",
            name: "欧洲铁派查价"
          },
          component: () => import("@/components/childern/railway/index.vue"),
        },
      ]
    },
    {
      path:'oceanship',
      name:'oceanship',
      meta:{
        title:'海运查价-Co-logistics',
        power: "boxprice",
        name: "海运查价"
      },
      component: () => import("@/layout/components/commonmenu/Menu.vue"),
      children:[
        {
          path:'fullcontain',
          name:'fullcontain',
          meta:{
            title:'海运整柜-Co-logistics',
            power: "boxprice",
            name: "海运整柜"
          },
          component: () => import("@/view/ocean/fullcontain.vue"),
        },
        {
          path:'fulllog',
          name:'fulllog',
          meta:{
            title:'海运审核-Co-logistics',
            power: "seareview",
            name: "海运审核"
          },
          component: () => import("@/view/ocean/fulllog.vue"),
        }
      ]
    },
    {
      path:'express',
      name:'express',
      meta:{
        title:'快递查价-Co-logistics',
        power: "selexpress", //selexpress
        name: "快递查价"
      },
      component: () => import("@/layout/components/commonmenu/Menu.vue"),
      children:[
        {
          path:'home',
          name:'home',
          meta:{
            title:'快递查价-Co-logistics',
            power: "selexpress",
            name: "快递查价"
          },
          component: () => import("@/view/express/index.vue"),
        },
      ]
    },
    {
      path:'other',
      name:'other',
      meta:{
        title:'其它单询-Co-logistics',
        power: "single_price_air",
        name: "其它单询"
      },
      component: () => import("@/view/airlist/other.vue"),
    },
    {
      path:'checktable',
      name:'checktable',
      meta:{
        title:'海派价格表-Co-logistics',
        power: "price_excel",
        name: "海派价格表"
      },
      component: () => import("@/components/childern/check/priceTable/index.vue"),
    },
    {
      path:'excel',
      name:'excel',
      meta:{
        noShow: true,
        title:'excel-Co-logistics',
        power: "price_excel",
        name: "excel"
      },
      component: () => import("@/components/childern/check/priceTable/components/excel.vue"),
    },
  ]
}